import React from 'react';
import { Link } from 'react-router-dom';

import '../css/Footer.css';

class Footer extends React.Component {
  render() {
    const currentPage = this.props.location.pathname;
    const currentYear = new Date().getFullYear();
    
    const getImage = () => {
      if( currentPage === '/contact' ) {
        return "url( '/images/mobile.jpg' )";
      } else if( currentPage === '/portfolio' ) {
        return "url( '/images/coding.jpg' )";
      } else if( currentPage === '/' ) {
        return "url( '/images/vaseRight.jpg' )";
      }
    }
    const footerBg = {
      backgroundImage: getImage(),
    }
    
    return (
      <footer style={ footerBg }>
        <p>&copy; Copyright { currentYear } ZEN Digital. <Link to='/termsAndConditions'>Terms &amp; Conditions</Link></p>
      </footer>
    );
  }
}

export default Footer;