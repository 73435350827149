import React from 'react';

import '../css/NotFound.css';

const NotFound = () => (
  <div className="notFound">
    <h2>Path you are looking for was not found.</h2>
  </div>
);

export default NotFound;