import React from 'react';
import { Link } from 'react-router-dom';

import { prices } from '../content';
import '../css/Pricing.css';

class PriceDisplay extends React.Component {
  state = {
    visibility: false
  }

  visibilityChange = () => {
    this.state.visibility === false ? this.setState({ visibility: true }) : this.setState({ visibility: false });
  }

  render() {
    const showItem = ( this.state.visibility === false ? 'hide' : null );

    return (
  <li>
    <h2>{ this.props.service }</h2>
    { this.state.visibility === false ? <button className="more" onClick={ this.visibilityChange }>Show more</button> : <button onClick={ this.visibilityChange }>Show less</button> }
    <div className={ `${ showItem }` }>
      <p>{ this.props.descr }</p>
      <h3>What is included:</h3>
      <ul>
        { Object.keys( this.props.points ).map( key => <li key={ key }>{ this.props.points[ key ] }</li> ) }
      </ul>
    </div>
    <h2 className="price">{ this.props.price }</h2>
  </li>
    );
  }
};

const Pricing = () => {
  return (
    <div className="pricing">
      <div className="parallax parallaxPricing"></div>
      <div className="prices">
        <h1>Pricing</h1>
        <ul>
          { Object.keys( prices ).map( key => <PriceDisplay
            key={ key }
            service={ prices[ key ].service }
            descr={ prices[ key ].descr }
            points={ prices[ key ].points }
            price={ prices[ key ].price }
            /> ) }
        </ul>
      </div>
      <div className="parallax parallaxPricing"></div>
      <div className="contactButton">
        <p className="contactMessage">Please contact us if you have any questions.</p>
        <Link to="/contact">CONTACT</Link>
      </div>
      <div className="parallax parallaxPricing"></div>
    </div>
  )
};

export default Pricing;