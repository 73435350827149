import React from 'react';

import '../css/ContactForm.css';

class ContactForm extends React.Component {
  state = {
    formStatus: ""
  }

  submitForm = e => {
    e.preventDefault();

    const templateParams = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value
    };

    window.emailjs.send('zen_digital', 'template_bZDE9L1U', templateParams)
    .then( response => {
      console.log('SUCCESS!', response.status, response.text);
      this.setState({
        formStatus: "success"
      });
    }, error => {
      console.log('FAILED...', error);
      this.setState({
        formStatus: "error"
      });
    });
  }

  render() {
    return (
      <div className="formWrapper">
        <form id="contactForm" onSubmit={ this.submitForm }>
          <h1>Contact form</h1>
          <div className="contactFields">
            <div className="inputs">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" required />
              <label htmlFor="email">Email</label>
              <input type="text" name="email" required />
              <label htmlFor="phone">Phone number</label>
              <input type="tel" name="phone" />
            </div>
            <div className="textarea">
              <label htmlFor="message">Message</label>
              <textarea
              name="message"
              cols="30"
              rows="10"
              required
              ></textarea>
            </div>
          </div>
          <input type="submit" value="Send" className={`btn-submit ${ this.state.formStatus }`}/>
          <h3 className={`confirmation ${ this.state.formStatus }`}>Thank you for your message. We will contact you shortly.</h3>
          <h3 className={`refusal ${ this.state.formStatus }`}>Sorry, but something went wrong, please reload the page and try again.</h3>
        </form>
      </div>
    )
  }
}

export default ContactForm;