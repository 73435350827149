import React from 'react';

import ContactForm from './ContactForm';
import { openingTimes } from '../content';

import '../css/Contact.css';

class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="contact">
        <div className="parallax parallaxContact"></div>
        <ContactForm />
        <div className="parallax parallaxContact"></div>
        <div className="openingTimes">
          <h1>Opening times:</h1>
          <ul className="weekdaysContact">
            { Object.keys( openingTimes ).map( key => (
              <li key={ key }>
                <span>{ openingTimes[ key ].day }</span>
                <span>
                  { 
                    openingTimes[ key ].open && openingTimes[ key ].close !== null ? `${ openingTimes[ key ].open } - ${ openingTimes[ key ].close }` : "Closed" }
                </span>
              </li>
            ) ) }
          </ul>
        </div>
        <div className="parallax parallaxContact"></div>
        <div className="otherContact">
          <h1>You can also contact us at:</h1>
          <p><a href="mailto:mj@zen-digital.co.uk">mj@zen-digital.co.uk</a><br/><br/><span>or</span><br/><br/><a href="tel:+447456314429">+44 7456 314429</a></p>
        </div>
        <div className="parallax parallaxContact"></div>
      </div>
    );
  }
}

export default Contact;