import React from 'react';
import { Link } from 'react-router-dom';

import '../css/Header.css';

class Header extends React.Component {
  state = {
    viewportWidth: 0,
    viewPosition: 'top'
  }
  
  componentDidMount() {
    window.addEventListener( 'scroll', this.scrollListener );
    window.addEventListener( 'resize', this.resizeListener );
    this.resizeListener();
  }
  
  componentWillUnmount() {
    window.removeEventListener( 'scroll', this.scrollListener );
    window.removeEventListener( 'resize', this.resizeListener );
  }
  
  scrollListener = () => {
    const scrollPosition = window.pageYOffset;
    if ( scrollPosition === 0 ) {
      this.setState({ viewPosition: 'top' });
    } else {
      this.setState({ viewPosition: 'scroll' });
    }
  }
  
  resizeListener = () => {
    const viewportWidth = window.innerWidth;
    
    this.setState({ viewportWidth });
  }

  scrollDown = () => {
    window.scrollTo( 0, 1 );
  }
  
  render() {
    let headerStyle = {};
    let arrowDown = {};
    let logoWrapperStyle = {};
    let logoImageStyle = {};
    let logoTypeStyle = {};
    let menuItems = {};
    
    if ( this.state.viewportWidth < 800 ) {
      if ( this.state.viewPosition === 'top' ) {
        headerStyle = {
          backgroundColor: 'transparent'
        };

        menuItems = {
          marginTop: 65,
          backgroundColor: '#fffff0',
          borderRadius: 12,
          opacity: .85
        };
      } else {
        headerStyle = {
          backgroundColor: '#fffff0',
          height: 40,
          borderBottom: '2px solid #572e8d'
        };

        logoWrapperStyle = {
          display: 'none'
        };

        menuItems = {
          backgroundColor: 'transparent'
        };
      };
    } else {
      if ( this.state.viewPosition === 'top' && this.props.location.pathname === '/' ) {
        headerStyle = {
          height: '100vh',
          backgroundColor: 'transparent'
        };

        arrowDown = {
          display: 'flex',
          position: 'absolute',
          top: '88vh',
          left: `${ this.state.viewportWidth / 2 + 20 }px`,
          cursor: 'pointer'
        };

        logoWrapperStyle = {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
          transform: 'scale( 1.6 ) translateX( 10vw )',
          borderRadius: 15,
          backgroundColor: '#fffff0'
        };
        
        menuItems = {
          display: 'none'
        };
      } else if ( this.state.viewPosition === 'top' ) {
        headerStyle = {
          backgroundColor: 'transparent'
        };
        
        menuItems = {
          margin: '0 8px 3px',
          backgroundColor: '#fffff0',
          borderRadius: 12,
          opacity: .75
        };
      } else {
        headerStyle = {
          height: 50,
          backgroundColor: '#fffff0',
          borderBottom: '2px solid #572e8d'
        };
        
        logoWrapperStyle = {
          padding: 0
        }
        
        logoImageStyle = {
          transform: 'scale( .5 )'
        };
        
        logoTypeStyle = {
          padding: 0,
        };
        
        menuItems = {
          backgroundColor: 'transparent'
        };
      };
    }

    return (
      <header style={ headerStyle } onScroll={ this.scrollListener } >
        <div
          style={ arrowDown }
          className="arrow"
          onClick={ this.scrollDown }
        >&or;</div>
        <div style={ logoWrapperStyle } className="logo">
          <img src="/images/lily.png" alt="Logo" style={ logoImageStyle } />
          <h1 style={ logoTypeStyle } >ZEN Digital</h1>
        </div>
        <ul>
          <li style={ menuItems }>
            <Link to="/" onClick={ this.scrollDown }>HOME</Link>
          </li>
          <li style={ menuItems }>
            <Link to="/portfolio">PORTFOLIO</Link>
          </li>
          <li style={ menuItems }>
            <Link to="/contact">CONTACT</Link>
          </li>
        </ul>
      </header>
    );
  }
};

export default Header;