import React from 'react';
import { Link } from 'react-router-dom';

import { team } from '../content';
import '../css/Team.css';

const Member = props => (
  <li className="member">
    <div className="memberLeft">
      <img src={ props.image } alt={ props.name } />
    </div>
    <div className="memberRight">
      <h2>{ props.name }</h2>
      { Object.keys( props.titles ).map( key => <h3 key={ key }>{ props.titles[ key ] }</h3> ) }
      { Object.keys( props.paragraphs ).map( key => <p key={ key }>{ props.paragraphs[ key ] }</p> ) }
      { props.moreInfo && <Link to="/maciekjarmula" >More info</Link> }
    </div>
  </li>
);

class Team extends React.Component {
  render() {
    return(
      <div className="team">
        <h1>Team members</h1>
        <ul className="members">
          { Object.keys( team ).map( key => <Member
            key={ key }
            image={ team[ key ].image }
            name={ team[ key ].name }
            titles={ team[ key ].titles }
            paragraphs={ team[ key ].paragraphs }
            moreInfo={ team[ key ].moreInfo }
          /> ) }
        </ul>
      </div>
    );
  }
}

export default Team;