import React from 'react';

import { partners } from '../content';

import '../css/Portfolio.css';

class Partner extends React.Component {
  render() {
    const { name, service, link } = this.props.partner;
    return (
      <li className="partner">
        <a href={ link } target="_blank" rel="noopener noreferrer">
          <div className={ `thumbnail number${ parseInt( this.props.id ) + 1 }` } ></div>
          <div className="partnerDescr">
            <h2>{ name }</h2>
            <p>{ service }</p>
          </div>
        </a>
      </li>
    );
  }
}

class Portfolio extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="portfolio">
        <div className="parallax parallaxPortfolio"></div>
        <div className="portfolioHeader">
          <h1>Our work</h1>
        </div>
        {/* <div className="parallax parallaxPortfolio"></div> */}
        <ul className="portfolioGrid">
          { Object.keys( partners ).map( key => (
            <Partner
            key={ key }
            id={ key }
            partner={ partners[ key ] }
            />
            ) ).reverse() }
        </ul>
        <div className="parallax parallaxPortfolio"></div>
      </div>
    );
  }
}

export default Portfolio;