import React from 'react';

const ListHorizontal = props => (
  <ul className={ props.listName }>
    { Object.keys( props.items ).map( key => (
      <li key={ key }><h3>{ props.items[ key ] }</h3></li>
    ) ) }
  </ul>
);

class HorizontalCarousel extends React.Component {
  state = {
    itemsList: []
  };

  componentDidMount() {
    //  Get a copy of an array
    let arrCopy = this.props.items;
    let arrOfThree = arrCopy;

    //  Reduce the size of an array to 3 items
    arrOfThree = arrOfThree.slice( 0, 3 );

    function swapItems() {
      //  Alter the order of the list
      arrCopy.push( arrCopy[ 0 ] );
      arrCopy.shift();
    }

    //  Produce new array as state via interval
    this.interval = setInterval(() => {
      swapItems();
      this.setState({ itemsList: arrOfThree });
    }, 2000);

    //  Set first state
    this.setState({ itemsList: arrOfThree });
  }

  componentWillUnmount() {
    //  Clear interval
    clearInterval( this.interval );
  }

  render() {
    return (
      <ul className={ `horizontalCarousel ${this.props.listName}` }>
        { Object.keys( this.state.itemsList ).map( key => (
          <li key={ key }><h3>{ this.props.items[ key ] }</h3></li>
        ) ) }
      </ul>
    );
  };
};

export { ListHorizontal, HorizontalCarousel };