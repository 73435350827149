import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import MaciekJarmula from './MaciekJarmula';
import Portfolio from './Portfolio';
import Pricing from './Pricing';
import Contact from './Contact';
import Terms from './TermsAndConditions';
import NotFound from './NotFound';

const Router = () => (
  <BrowserRouter>
    <Route path='/' component={ Header } />
    <div id="section">
      <Switch>
        <Route exact path='/' component={ Home } />
        <Route exact path='/maciekjarmula' component={ MaciekJarmula } />
        <Route exact path='/portfolio' component={ Portfolio } />
        <Route exact path='/pricing' component={ Pricing } />
        <Route exact path='/contact' component={ Contact } />
        <Route exact path='/termsAndConditions' component={ Terms } />
        <Route component={ NotFound } />
      </Switch>
    </div>
    <Route path='/' component={ Footer } />
  </BrowserRouter>
);

export default Router;