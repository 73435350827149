import React from 'react';
import { Link } from 'react-router-dom';

import SocialMedia from './SocialMedia';
import { HorizontalCarousel } from './Lists';
import Team from './Team';

import { offer, offerItems, perksItems, prices } from '../content';
import '../css/Home.css';

/* Add Yell widget */

const AboutUs = () => (
  <div className="topic">
    <h1>About us</h1>
    <p>ZEN Digital was established in the first quarter of 2019 in the London Borough of Barnet. We are a newcomers in the industry, but remember, lack of experience does not mean lack of expertise. We create products with use of the newest technologies supported by biggest brands like: Adobe, Amazon, Google.</p>
    <p>We are a versatile digital agency, so there is no need to hire multiple specialists. We can take care of all your needs in one place and all of it within affordable price range. We treat all our customers as individuals, which means what you get - and pay for - depends on what you really need.</p>
    <p>We will do what is needed to make sure all of our products fit your requirements like a tailor made suit. Starting from Branding and Development, going through Search Engine Optimisation, Hosting and Maintaining and proceeding to Advertising and taking care of your Social Media.</p>
    <SocialMedia />
  </div>
);

const OurGoals = () => (
  <div className="topic">
    <h1>Our goals</h1>
    <p>We take pride in developing not just a product but your image as well as ours. Your progress is as important for us as our own, by putting this principle to use we make sure you are happy with effects of our efforts.</p>
    <p>In todays world online presence is essential if you are trying to reach your audience. With the amount of people who can access information about you online you will be exposed to countless opportunities. To take the full advantage of this you might need some help...<br/>We are here for you.</p>
  </div>
  );

const Services = () => (
  <div className="topic services">
    <h1>Our offer</h1>
    <p>Below you can find a list of main services we have in our offer. This list is uninterruptedly growing, so if you can not see below what you need, please do not be afraid to ask us directly.</p>
    <ul>
      {
        Object.keys( offer ).map( service => <li
          key={ service }
        >{ offer[ service ] }</li> )
      }
    </ul>
  </div>
);

  // const Opinions = () => (
  //   <div className="opinions">
  //     <script type="text/javascript" src="//sites.yext.com/255855-reviews.js"></script>
  //   </div>
  // );

class PriceDisplay extends React.Component {
  state = {
    visibility: false
  }

  visibilityChange = () => {
    this.state.visibility === false ? this.setState({ visibility: true }) : this.setState({ visibility: false });
  }

  render() {
    const showItem = ( this.state.visibility === false ? 'hide' : null );

    return (
  <li>
    <h2>{ this.props.service }</h2>
    { this.state.visibility === false ? <button className="more" onClick={ this.visibilityChange }>Show more</button> : <button onClick={ this.visibilityChange }>Show less</button> }
    <div className={ `${ showItem }` }>
      <p>{ this.props.descr }</p>
      <h3>What is included:</h3>
      <ul>
        { Object.keys( this.props.points ).map( key => <li key={ key }>{ this.props.points[ key ] }</li> ) }
      </ul>
    </div>
    <h2 className="price">{ this.props.price }</h2>
  </li>
    );
  }
};

class Home extends React.Component {
  state = {
    viewportWidth: 0,
    viewPosition: 'top'
  }
  componentDidMount() {
      window.scrollTo(0,0);
      window.addEventListener( 'scroll', this.scrollListener );
      window.addEventListener( 'resize', this.resizeListener );
      this.resizeListener();
    }
    
    componentWillUnmount() {
      window.removeEventListener( 'scroll', this.scrollListener );
      window.removeEventListener( 'resize', this.resizeListener );
  }

  scrollListener = () => {
    const scrollPosition = window.pageYOffset;
    if ( scrollPosition === 0 ) {
      this.setState({ viewPosition: 'top' });
    } else {
      this.setState({ viewPosition: 'scroll' });
    }
  }

  resizeListener = () => {
    const viewportWidth = window.innerWidth;

    this.setState({ viewportWidth });
  }

  render() {
    let firstParallax = {};

    if ( this.state.viewportWidth >= 800 ) {
      if ( this.state.viewPosition === 'top' ) {
        firstParallax = {
          height: '100vh',
          backgroundImage: 'url( "/images/vaseRightPencilSharpPurple.jpg" )'
        };
      }
    };

    return (
      <div className="home">
        <div style={ firstParallax } className="parallax parallaxHome"></div>
        <AboutUs />
        <div className="parallax parallaxHome"></div>
        <HorizontalCarousel  listName='offer' items={ offerItems } />
        <div className="parallax parallaxHome"></div>
        <OurGoals />
        <div className="parallax parallaxHome"></div>
        <HorizontalCarousel  listName='perks' items={ perksItems } />
        <div className="parallax parallaxHome"></div>
        <Team />
        <div className="parallax parallaxHome"></div>
        <Services />
        {
        // 
        //   <Opinions />
        //   <div className="parallax parallaxHome"></div>
        //
        }
        <div className="parallax parallaxHome"></div>
        <div className="topic prices">
          <h1>Pricing</h1>
          <ul>
            { Object.keys( prices ).map(
              key => <PriceDisplay
                key={ key }
                service={ prices[ key ].service }
                descr={ prices[ key ].descr }
                points={ prices[ key ].points }
                price={ prices[ key ].price }
              /> )
            }
          </ul>
          </div>
          <div className="parallax parallaxHome"></div>
          <div className="contactButton">
            <p className="contactMessage">Please contact us if you have any questions.</p>
            <Link to="/contact">CONTACT</Link>
          </div>
          <div className="parallax parallaxHome"></div>
      </div>
    );
  }
};

export default Home;