import React from 'react';

import '../css/TermsAndConditions.css';

const Terms = () => (
  <div className="tAndC">
    {window.scrollTo(0,0)}
    <h1>Terms &amp; Conditions</h1>
    <h3>The following terms and conditions apply to all development, design and other services provided by ZEN Digital to the Client.</h3>
    <ol>
      <li>
        <h2>Acceptance</h2>
        <p>It is not necessary for any Client to have signed an acceptance of these terms and conditions for them to apply. If a Client accepts a quote then the Client will be deemed to have satisfied themselves as to the terms applying and have accepted these terms and conditions in full.</p>
        <p>Please read these terms and conditions carefully. Any purchase or use of our services implies that you have read and accepted our terms and conditions.</p>
      </li>
      <li>
        <h2>Charges</h2>
        <p>Charges for services to be provided by ZEN Digital are defined in the project quotation that the Client receives via e-mail. Quotations are valid for a period of 30 days. ZEN Digital reserves the right to alter or decline to provide a quotation after expiry of the 30 days.</p>
        <p>Unless agreed otherwise with the Client, all services require an advance payment of a minimum of thirty (30) percent of the project quotation total before the work is supplied to the Client for review. A second charge of thirty (30) percent is required after the development stage, with the remaining forty (40) percent of the project quotation total due upon completion of the work, prior to upload to the server or release of materials.</p>
        <p>Payment for services is due by cash or bank transfer. Bank details will be made available on invoices.</p>
      </li>
      <li>
        <h2>Client Review</h2>
        <p>ZEN Digital will provide the Client with an opportunity to review the appearance and content of the website during the design phase and once the overall website development is completed. At the completion of the project, such materials will be deemed to be accepted and approved unless the Client notifies ZEN Digital otherwise within ten (10) working days of the date the materials are made available to the Client.</p>
      </li>
      <li>
        <h2>Turnaround Time and Content Control</h2>
        <p>ZEN Digital will install and publicly post or supply the Client's website by the date specified in the project proposal, or at date agreed with Client upon ZEN Digital receiving initial payment, unless a delay is specifically requested by the Client and agreed by ZEN Digital.</p>
        <p>In return, the Client agrees to delegate a single individual as a primary contact to aid ZEN Digital with progressing the commission in a satisfactory and expedient manner.</p>
        <p>During the project, ZEN Digital will require the Client to provide website content: text, images, movies and sound files.</p>
      </li>
      <li>
        <h2>Failure to provide required website content</h2>
        <p>ZEN Digital is a small business, to remain efficient we must ensure that work we have programmed is carried out at the scheduled time. On occasions we may have to reject offers for other work and enquiries to ensure that your work is completed at the time arranged.</p>
        <p>This is why we ask that you provide all the required information in advance. On any occasion where progress cannot be made with your website because we have not been given the required information in the agreed time frame, and we are delayed as result, we reserve the right to impose a surcharge of up to 25%. If your project involves Search Engine Optimisation we need the text content for your site in advance so that the SEO can be planned and completed efficiently.</p>
        <p>If you agree to provide us with the required information and subsequently fail to do within one week of project commencement we reserve the right to close the project and the balance remaining becomes payable immediately. Simply put, all the above condition says is do not give us the go ahead to start until you are ready to do so.</p>
        <p>NOTE: Text content should be delivered as a Microsoft Word, email (or similar) document with the pages in the supplied document representing the content of the relevant pages on your website. These pages should have the same titles as the agreed website pages. Use our contact form or email us if you need clarification on this.</p>
      </li>
      <li>
        <h2>Payment</h2>
        <p>Invoices will be provided by ZEN Digital upon completion but before publishing the live website. Invoices are normally sent via email, however the Client may choose to receive hard copy invoices. Invoices are due upon receipt. Accounts that remain unpaid thirty (30) days after the date of the invoice will be assessed a service charge in the amount of the higher of: five percent (5%) of total amount due or fifty pounds sterling (£50) per month.</p>
      </li>
      <li>
        <h2>Additional Expenses</h2>
        <p>Client agrees to reimburse ZEN Digital for any additional expenses necessary for the completion of the work. Examples would be purchase of special fonts, stock photography, customised hosting etc.</p>
      </li>
      <li>
        <h2>Web Browsers</h2>
        <p>ZEN Digital makes every effort to ensure websites are designed to be viewed by the majority of visitors. Websites are designed to work with the most popular current browsers (e.g. Firefox, Edge, Google Chrome, Safari and Opera). Client agrees that ZEN Digital cannot guarantee correct functionality with all browser software across different operating systems.</p>
        <p>ZEN Digital cannot accept responsibility for web pages which do not display acceptably in new versions of browsers released after the website have been designed and handed over to the Client. As such, ZEN Digital reserves the right to quote for any work involved in changing the website design or website code for it to work with updated browser software.</p>
      </li>
      <li>
        <h2>Default</h2>
        <p>Accounts unpaid thirty (30) days after the date of invoice will be considered in default.</p>
        <p>If the Client in default maintains any information or files on ZEN Digital's online services, ZEN Digital will, at its discretion, remove all such material from its cloud space. ZEN Digital is not responsible for any loss of data incurred due to the removal of the service. Removal of such material does not relieve the Client of the obligation to pay any outstanding charges assessed to the Client's account. Clients with accounts in default agree to pay ZEN Digital reasonable expenses, including legal fees and costs for collection by third-party agencies, incurred by ZEN Digital in enforcing these Terms and Conditions.</p>
      </li>
      <li>
        <h2>Termination</h2>
        <p>Termination of services by the Client must be requested in a written notice and will be effective on receipt of such notice. Telephone requests for termination of services will not be honoured until and unless confirmed in writing. The Client will be invoiced for work completed to the date of first notice of cancellation for payment in full within thirty (30) days.</p>
      </li>
      <li>
        <h2>Indemnity</h2>
        <p>All ZEN Digital services may be used for lawful purposes only. You agree to indemnify and hold ZEN Digital harmless from any claims resulting from your use of our service that damages you or any other party.</p>
      </li>
      <li>
        <h2>Copyright</h2>
        <p>The Client retains the copyright to data, files and graphic logos provided by the Client, and grants ZEN Digital the rights to publish and use of such materials. The Client must obtain permission and rights to use any information or files that are copyrighted by a third party. The Client is further responsible for granting ZEN Digital permission and rights for use of the same and agrees to indemnify and hold harmless ZEN Digital from any and all claims resulting from the Client's negligence or inability to obtain proper copyright permissions. A contract for website design and/or placement shall be regarded as a guarantee by the Client to ZEN Digital that all such permissions and authorities have been obtained. Evidence of permissions and authorities may be requested.</p>
      </li>
      <li>
        <h2>Standard Media Delivery</h2>
        <p>Unless otherwise specified in the project quotation, these Terms &amp; Conditions assumes that any text will be provided by the Client in electronic format (ASCII text files delivered on external drive or via email or FTP) and that all photographs and other graphics will be provided electronically in .svg, .jpg, .jpeg, .png format.</p>
      </li>
      <li>
        <h2>Credits</h2>
        <p>A link to ZEN Digital will appear in either small type or by a small graphic at the bottom of the Client's website. If a graphic is used, it will be designed to fit in with the overall site design. If a client requests that the design credit shall be removed, a nominal fee of 10% of the total development charges will be applied unless total development charges are less than £3000, a fixed fee of £250 will be applied. The Client also agrees that the website developed for the Client may be presented in ZEN Digital’s portfolio.</p>
      </li>
      <li>
        <h2>Access Requirements</h2>
        <p>If the Client's website is to be installed on a third-party server of Client’s choice, ZEN Digital must be granted temporary read/write access to the Client's storage directories which must be accessible via SSH. Depending on the specific nature of the project, other resources might also need to be configured on the server.</p>
      </li>
      <li>
        <h2>Post-Placement Alterations</h2>
        <p>ZEN Digital cannot accept responsibility for any alterations caused by a third party occurring to the Client's pages once installed. Such alterations include, but are not limited to additions, modifications or deletions.</p>
      </li>
      <li>
        <h2>Domains</h2>
        <p>ZEN Digital may purchase domain names on behalf of the Client. Payment and renewal of those domain names is the responsibility of the Client. The loss, cancellation or otherwise of the domain brought about by non or late payment is not the responsibility of ZEN Digital. The Client should keep a record of the due dates for payment to ensure that payment is received in good time.</p>
      </li>
      <li>
        <h2>General</h2>
        <p>These Terms and Conditions supersede all previous representations, understandings or agreements. The Client's signature below or payment of an advance fee constitutes agreement to and acceptance of these Terms and Conditions. Payment online is an acceptance of our terms and conditions.</p>
      </li>
      <li>
        <h2>Social Media Management</h2>
        <p>Social Media Marketing and Management is defined as helping a client to promote their products or services through social media channels. ZEN Digital will honour the components of your chosen social media package, providing an agreement to a minimum 3 months contract is served and monthly payments are received in advance. In the event that payment is not received on time, we regret that further work will be halted until this is rectified.</p>
      </li>
      <li>
        <h2>Governing Law</h2>
        <p>This Agreement shall be governed by English Law.</p>
      </li>
      <li>
        <h2>Liability</h2>
        <p>ZEN Digital hereby excludes itself, its Employees and or Agents from all and any liability from:</p>
        <ul>
          <li>Loss or damage caused by any inaccuracy</li>
          <li>Loss or damage caused by omission</li>
          <li>Loss or damage caused by delay or error, whether the result of negligence or other cause in the production of the web site</li>
        </ul>
        <p>Loss or damage to Client’s designs/artwork/photos, supplied for the site. Immaterial whether the loss or damage results from negligence or otherwise.</p>
        <p>The entire liability of ZEN Digital to the Client in respect of any claim whatsoever or breach of this Agreement, whether or not arising out of negligence, shall be limited to the charges paid for the Services under this Agreement in respect of which the breach has arisen.</p>
      </li>
      <li>
        <h2>Severability</h2>
        <p>In the event any one or more of the provisions of this Agreement shall be held to be invalid, illegal or unenforceable, the remaining provisions of this Agreement shall be unimpaired and the Agreement shall not be void for this reason alone. Such invalid, illegal or unenforceable provision shall be replaced by a mutually acceptable valid, legal and enforceable provision, which comes closest to the intention of both parties.</p>
      </li>
    </ol>
  </div>
);

export default Terms;