import React from 'react';

import { team } from '../content';
import '../css/MaciekJarmula.css';

class MaciekJarmula extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    const { name, image, titles, products, skills, aboutMe } = team[0];
    return (
      <div className="maciekjarmula">
        <div className="profileWrapper">
          <div className="left">
            <img src={ image } alt={ name } />
          </div>
          <div className="right">
            <h1>{ name }</h1>
            { Object.keys( titles ).map( key => <h3 key={ key }>{ titles[ key ] }</h3> ) }
          </div>  
        </div>
        <div className="priorities">
          { Object.keys( products ).map( key => (
            <div key={ key }>
              { products[ key ].icon && <img src={ products[ key ].icon } alt='Icon' /> }
              <h3>{ products[ key ].name }</h3>
              <p>{ products[ key ].descr }</p>
            </div>
          ) ) }
        </div>
        <ul>
          { Object.keys( skills ).map( key => (
            <li key={ key }>
              <p>
                { Object.keys( skills[ key ] ) }
              </p>
              <div>
                <div className="skill" style={ {width: `${Object.values( skills[ key ] )}%`} }></div>
                <p>{ Object.values( skills[ key ] ) }%</p>
              </div>
            </li>
          ) ) }
        </ul>
        <div className="aboutMe">
            { Object.keys( aboutMe ).map( key => <p key={ key }>{ aboutMe[ key ] }</p> ) }
        </div>
      </div>
    )
  }
}

export default MaciekJarmula;