import React from 'react';

import '../css/SocialMedia.css';

const SocialMedia = () => {
  return (
    <div className="socialMedia">
      <span>Find us on:</span>
      <a
        href="https://www.google.com/maps/place/ZEN+Digital/@51.6216604,-0.1705158,12z/data=!4m8!1m2!2m1!1szen-digital!3m4!1s0x4876191346bd075f:0x73833afb9470dff6!8m2!3d51.6412717!4d-0.1636544"
        target="_blank"
        rel="noopener noreferrer"
      >Google</a>
      <a
        href="https://www.linkedin.com/company/zen-digital-uk"
        target="_blank"
        rel="noopener noreferrer"
      >LinkedIn</a>
      <a
        href="https://www.facebook.com/zendigitalUK/"
        target="_blank"
        rel="noopener noreferrer"
      >Facebook</a>
    </div>
  )
};

export default SocialMedia;